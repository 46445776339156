import './App.css';
import MailChimpWaitList from './components/MailChimpWaitList';

function App() {

  return (
    <div className="App">
      <span className="company">MADSTACKZ</span>
      <div className="tagline">Redefining Fantasy Sports</div>
      <div className="x-contact"><span>DM us on</span>&nbsp;&nbsp;
        <a href="https://twitter.com/Madstackz">
          <img id="x-logo" src="x-logo-white.png" alt="X" />
        </a>
      </div>
      <MailChimpWaitList />
    </div>
  );
}

export default App;
