import { Component } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import './MailChimpWaitList.css';

const url = 'https://madstackz.us21.list-manage.com/subscribe/post?u=26982c30f03ff515731583453&amp;id=63939aab7f&amp;f_id=00d1f4e6f0';

const CustomForm = ({ status, message, onValidated }: { status: any, message: any, onValidated: any }) => {
    let email: HTMLInputElement | null;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    return (
        <div
            style={{
                // background: "#efefef",
                borderRadius: 2,
                padding: 10,
                // display: "inline-block"
            }}
        >
            {status === "sending" && <div style={{ color: "white" }}>sending...</div>}
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "white" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            <br />
            <input
                style={{ fontSize: "1em", padding: 5, width: 200 }}
                ref={node => (email = node)}
                type="email"
                placeholder="Your email"
            />
            <span style={{ paddingLeft: 10 }}>
                <button style={{ fontSize: "1em", padding: 5, width: 60 }} onClick={submit}>
                    Join
                </button>
            </span>

        </div >
    );
};

export default class MailChimpWaitList extends Component {
    render() {
        return (
            <div className="waitlist-container">
                <span className="waitlist">Join our Waitlist</span>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={(formData: any) => subscribe(formData)}
                        />
                    )}
                />
            </div>
        );
    }
}
